/*PRODUCT LIST*/
h3.ResourceItem__Title {line-height:38px;}
#Activator-taggedWith1 {display:none;}

.gv-pagination { height:80px; padding:20px 0 0 0; border-top:1px solid #d4d4d4; width:100%;}
.gv-pagination .Polaris-ButtonGroup {justify-content:center;}

/*SYNC ACTIONS*/
.shopify-sync .Polaris-Stack__Item {display:none;}
.shopify-sync .Polaris-Stack__Item--fill {display:inherit;}
